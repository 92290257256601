.sidebar-container {
  display: flex;
  /* flex-direction: row; */
  justify-content: start;
  align-items: top;
  color: #fff;
  font-family: monospace;
  position: absolute;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: column !important;
  width: 100vw;
  background-color: rgba(35, 55, 75, 0.9);
}

.sidebar {
  display: flex;
  transition: opacity 0.5s ease-in-out;
}

.top_section {
  background-color: rgba(35, 55, 75, 0.9);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px;
}

.bars {
  display: flex;
  font-size: 25px;
  text-align: center;
}

.sidebar-item {
  background-color: rgba(35, 55, 75, 0.9);
  display: flex;
  color: #fff;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px;
  gap: 15px;
  transition: opacity 0.5s ease-in-out;
}

.sidebar-item:hover {
  background: lightblue;
  color: #000;
  transition: all 0.5s;
  cursor: pointer;
}

.active {
  background: lightblue;
  color: #000;
}

.sidebar-icon,
.sidebar-link-text {
  font-size: 2em;
}

.sidebar-window {
  background-color: rgba(35, 55, 75, 0.9);
  text-align: center;
  height: 80vh;
  /* width: 80vw; */
  overflow-y: scroll;
  overflow-x: hidden;
  transition: opacity 0.5s linear;
  width: 100vw;
}

.sidebar-window.hidden {
  width: 0px;
  height: 0px;
}

@media screen and (min-width: 600px) {
  .sidebar-window {
    /* height: 40vh; */
    /* width: 50vw; */
    /* width: 50vw; */
  }
}

@media screen and (max-width: 600px) {
  .sidebar-window {
    /* height: 40vh; */
    /* width: 50vw; */
    width: 100vw;
  }
  /* .sidebar-container {
    flex-direction: column !important;
    width: 100vw;
    background-color: rgba(35, 55, 75, 0.9);
  } */
  /* .sidebar{
    display: flex;
  } */
}


