.container {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.results {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px; /* 2023-06-08 Update */
}

.results-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start; /* 2023-06-08 Update */
  width: 96%; /* 2023-06-08 Update */
  border-top: 2px solid white;
  padding: 5px;  /* 2023-06-08 Update */
}

.results-item:hover {
  cursor: pointer;
  background-color: lightblue;
}

.results-item-column {
  max-width: 98%; /* 2023-06-08 Update */

  /* white-space:nowrap; 
  overflow:hidden; 
  text-overflow:ellipsis;  */

  overflow:hidden;
  line-height: 1rem;
  max-height: 3.9rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;

}

.results-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.results-item-name {
  justify-content: baseline;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .results-item {
    /* flex-direction: row; */
  }
}

.search-input {
  width: 70vw;
  height: 1.8rem;
}
