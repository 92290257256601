.currentLocationCenterButton {
  position: fixed;
  z-index: 900;
  bottom: 2vh;
  left: 50vw;
  margin-bottom: 15px;
  width: 9vw;
  height: 5vh;
  border-radius: 50px;
  background: rgba(158, 157, 157, 0.8)
    url("../../assets/currentlocationmarker.png");
  background-size: 12vw;
  background-position: center;
  border: none;
}

.currentLocationCenterButton:hover {
  cursor: pointer;
}

.currentLocationCenterButton:active {
  box-shadow: 1px 1px 1px #888888;
  opacity: 1;
}

.currentLocationCenterButton:disabled {
  display: none;
}

@media all and (min-width: 850px) {
  .currentLocationCenterButton {
    width: 50px;
    height: 50px;
    background-size: 65px;
    background-repeat: no-repeat;
    opacity: 0.8;
  }
}
